import React from 'react';
import Age from './AgeComponent';
import './Portfolio.css'

const Portfolio: React.FC = () => {
    return (
        <div className="main-container">
            <h2 className="index-headings">O MNĚ</h2>
            <p className="p-about">
                Abych se představil, mé jméno je Ondřej Pacovský, jsem z Hradce Králové a v tuto chvíli studuji na SŠ a VOŠ Aplikované Kybernetiky. Již od malička jsou moji zálibou technologie, vždy jsem se jim s radostí věnoval a učil se nové věci.
                <br />
                <br />
                Od roku 2019 až do teď se věnuji vývoji jednoduchých webových stránek v základních technologiích. S přestupem na střední školu jsem přišel na chuť síťařině a programování mikroprocesorů. Rozšířil jsem si znalosti o vývoj desktopových a serverových aplikací, UI/UX, práci s bitmapovou a vektorovou grafikou a mnoho dalšího. Všechny mé zkušenosti a certifikace lze nalézt na mém LinkedInu.
                <br />
                <br />
                Baví mě pracovat na nových projektech a určitě se ničemu nebráním. Spolupracoval jsem se spousty menších, ale i větších herních serverů, na kterých jsem nabral zkušenosti s vedením a prací v týmu. Ve volném čase vypomáhám s projektem LearnHTML, který se zaměřuje na pomoc s technickými problémy v oblasti programování.
            </p>
            <h2 className="index-headings">INFORMACE</h2>
                <div className="info-section">
                <div className="info-item">
                    <div className="line-heading">Věk</div>
                    <p><Age /></p>
                </div>
                <div className="info-item">
                    <div className="line-heading">Bydliště</div>
                    <p>Hradec Králové</p>
                </div>
                <div className="info-item">
                    <div className="line-heading">Krátký popis</div>
                    <p>Technologický nadšenec</p>
                </div>
                <div className="info-item">
                    <div className="line-heading">Zájmy</div>
                    <p>Vývoj webů, mikroprocesorová technika, Síťařina</p>
                </div>
                <div className="info-item">
                    <div className="line-heading">Aktuální studium</div>
                    <p>SŠ a VOŠ Aplikované Kybernetiky (2022-2026)</p>
                </div>
                <div className="info-item">
                    <div className="line-heading">Předešlé studium</div>
                    <p>ZŠ a MŠ Mandysova (2014-2022)</p>
                </div>
            </div>
        </div>
    );
};

export default Portfolio;
