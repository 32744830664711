import React from 'react';
import './timeline.css';

interface Project {
    date: string;
    title: string;
    description: string;
    logo: string;
    url: string;
}

const projects: Project[] = [
    { date: '02/2021 - 03/2021', title: 'Scratch Wars', url: 'https://www.scratchwars.cz', logo: './assets/projects/scratch-wars.png', description: 'V tu dobu jedna z největších karetních her a komunit v Česku, kde jsem se podílel na správě jejich discord serveru na pozici moderátora.', },
    { date: '10/2022 - Teď', title: 'Wolfriders.cz', url: 'https://discord.com/invite/PpKp2p8VJZ/',logo: './assets/projects/wolfriders.png', description: 'Československý herní portál, který jsem měl možnost spoluvlastnit přes 2 roky, vedl jsem tým a spravoval servery ve hrách Counter-Strike 2 (dříve CS:GO), FiveM a mnoho dalších.' },
    { date: '03/2024 - Teď', title: 'Halmine.cz', url: 'https://halmine.cz/',logo: './assets/projects/halmine.png', description: 'Jeden z nejhranějších CZ/SK minecraft serverů, kde mi je ctí po boku ostatních pomáhat chodu serveru jako helper. Zároveň také spravuji oficiální wikipedii s návody pro začáteční, ale i pokročilé hráče.', },
    { date: '07/2024 - Teď', title: 'EternalRust.eu', url: 'https://eternalrust.eu',logo: './assets/projects/eternalrust.png', description: 'Můj vlastní EU/CZ Rust server, který je v tuto chvíli v přípravě. Plánované spuštění je někdy v průběhu záři. Už teď se do projektu dalo dost peněz a doufám že se vše povede dle plánů.', },
];


const Timeline: React.FC = () => {
    return (
        <div className="timeline-container">
            <h1>PROJEKTY</h1>
            <p>Seznam mých bývalých a stávajících projektů, nebo čeho jsem byl součástí.</p>
            <br />
            <div className="timeline">
                {projects.map((project, index) => (
                    <div key={index} className="timeline-item">
                        <div className="timeline-date">{project.date}</div>
                        <div className="timeline-line">
                            <div className="timeline-dot"></div>
                        </div>
                        <a href={project.url} className="timeline-link" target="_blank" rel="noopener noreferrer">
                            <div className="timeline-content">
                                <div>
                                    <h2>{project.title}</h2>
                                    <p>{project.description}</p>
                                </div>
                                <div className="timeline-logo">
                                    <img src={project.logo} alt={project.title} />
                                </div>
                            </div>
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Timeline;
